import _ from 'lodash';
import moment from 'moment';

export default (
    state = {
        totalEvent: 0,
        selectedRoadshow: {
            name: '',
            address: '',
            city: '',
            startTime: '00:00',
            endTime: '00:00',
            isPublish: true,
            eventDate: moment().format('DD/MM/YYYY'),
            modifiedDate: ''
        }
    },
    action
) => {
    let roadshows = {};
    let roadshowUsers = {};

    function pad(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    switch (action.type)
    {
        case 'ROADSHOW_SHOW_LOADER_ALL_ROADSHOWS':
            return Object.assign({}, state, {
                allRoadshowsLoader: true
            });
        case 'ROADSHOW_GET_ALL_ROADSHOWS':
            roadshows = _.map(action.roadshows, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                const eventDate = row.eventDate.split('/');
                row.eventDate = `${eventDate[2]}-${eventDate[1]}-${eventDate[0]}`;
                row.key = key;
                row.eventTime = row.startTime + ' - ' + row.endTime;
                
                return row;
            });

            return Object.assign({}, state, {
                allRoadshows: roadshows,
                allRoadshowsLoader: false,
            });
        case 'ROADSHOW_SHOW_LOADER_GET_ROADSHOW':
                return Object.assign({}, state, {
                    getRoadshowLoader: true,
                    selectedRoadshow: {
                        name: '',
                        address: '',
                        city: '',
                        startTime: '00:00',
                        endTime: '00:00',
                        isPublish: true,
                        eventDate: moment().format('DD/MM/YYYY')
                    }
                });
        case 'ROADSHOW_SELECT_ROADSHOW':
            return Object.assign({}, state, {
                selectedRoadshow: action.roadshow,
                getRoadshowLoader: false
            });
        case 'ROADSHOW_SHOW_LOADER_INSERT_ROADSHOW':
        case 'ROADSHOW_SHOW_LOADER_UPDATE_ROADSHOW':
            return Object.assign({}, state, {
                isLoader: true
            });
        case 'ROADSHOW_HIDE_LOADER_INSERT_ROADSHOW':
            return Object.assign({}, state, {
                isLoader: false
            });
        case 'ROADSHOW_HIDE_LOADER_UPDATE_ROADSHOW':
            return Object.assign({}, state, {
                isLoader: false,
                selectedRoadshow: action.data
            });

        case 'ROADSHOW_SHOW_LOADER_ALL_USERS':
            return Object.assign({}, state, {
                allRoadshowUsersLoader: true
            });
        case 'ROADSHOW_GET_ALL_USERS':
            roadshowUsers = _.map(action.roadshowUsers, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                row.birthday = moment(`${parseInt(row.birthday.year)}-${pad(parseInt(row.birthday.month) + 1, 2)}-${pad(parseInt(row.birthday.date), 2)}`).format('DD MMMM YYYY');
                return row;
            });

            return Object.assign({}, state, {
                allRoadshowUsers: roadshowUsers,
                allRoadshowUsersLoader: false,
            });
        
        default: return state;
    }
};