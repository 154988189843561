import { combineReducers } from 'redux';

// Import your reducers here...
import user from './user';
import agent from './agent';
import roadshow from './roadshow';
import talkshow from './talkshow';
import gallery from './gallery';
import article from './article';

export default combineReducers({
    user,
    agent,
    roadshow,
    talkshow,
    gallery,
    article
});