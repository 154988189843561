import _ from 'lodash';

export default (
    state = {
        allArticleCategories: [],
        allArticles: [],

        selectedArticleCategory: {
            name: '',
            orderNumber: 0,
            isPublish: true,
            modifiedDate: ''
        },

        selectedArticle: {
            title: '',
            idCategory: '',
            isPublish: true,
            modifiedDate: '',
            seoTitle: '',
            seoDescription: '',
            excerpt: ''
        },
    },
    action
) => {
    let categories = [];
    let articles = [];

    switch (action.type)
    {
        case 'ARTICLE_SHOW_LOADER_ALL_CATEGORIES':
            return Object.assign({}, state, {
                allArticleCategoriesLoader: true
            });

        case 'ARTICLE_GET_ALL_CATEGORIES':
            categories = _.map(action.categories, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                row.key = key;
                
                return row;
            });

            categories = categories.sort(function(a, b){
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            });

            return Object.assign({}, state, {
                allArticleCategories: categories,
                allArticleCategoriesLoader: false,
            });

        case 'ARTICLE_SHOW_LOADER_GET_CATEGORY':
            return Object.assign({}, state, {
                getArticleCategoryLoader: true,
                selectedArticleCategory: {
                    name: '',
                    orderNumber : 0,
                    isPublish: true,
                    modifiedDate: ''
                }
            }); 

        case 'ARTICLE_SELECT_CATEGORY':
            return Object.assign({}, state, {
                selectedArticleCategory: action.category,
                getArticleCategoryLoader: false
            });

        case 'ARTICLE_SHOW_LOADER_INSERT_CATEGORY':
        case 'ARTICLE_SHOW_LOADER_UPDATE_CATEGORY':
            return Object.assign({}, state, {
                isLoader: true
            });

        case 'ARTICLE_HIDE_LOADER_INSERT_CATEGORY':
            return Object.assign({}, state, {
                isLoader: false
            });
            
        case 'ARTICLE_HIDE_LOADER_UPDATE_CATEGORY':
            return Object.assign({}, state, {
                isLoader: false,
                selectedArticleCategory: action.data
            });

        case 'ARTICLE_SHOW_LOADER_ALL_ARTICLES':
            return Object.assign({}, state, {
                allArticlesLoader: true
            });

        case 'ARTICLE_GET_ALL_ARTICLES':
            articles = _.map(action.articles, (row, key) => {
                row.key = key;
                row.status = row.isPublish === true ? 'Publish' : 'Draft';

                return row;
            });

            return Object.assign({}, state, {
                allArticles: articles ,
                allArticlesLoader: false,
            });

        case 'ARTICLE_SHOW_LOADER_GET_ARTICLE':
            return Object.assign({}, state, {
                getArticleLoader: true,
                selectedArticle: {
                    title: '',
                    bannerFileName: '',
                    banner: '',
                    isPublish: true,
                    modifiedDate: '',
                }
            }); 
        
        case 'ARTICLE_SELECT_ARTICLE':
            return Object.assign({}, state, {
                selectedArticle: action.article,
                getArticleLoader: false,                
            });
        
        case 'ARTICLE_SHOW_LOADER_INSERT_ARTICLE':
        case 'ARTICLE_SHOW_LOADER_UPDATE_ARTICLE':
            return Object.assign({}, state, {
                isLoader: true
            });

        case 'ARTICLE_HIDE_LOADER_INSERT_ARTICLE':
            return Object.assign({}, state, {
                isLoader: false
            });
            
        case 'ARTICLE_HIDE_LOADER_UPDATE_ARTICLE':
            return Object.assign({}, state, {
                isLoader: false,
                selectedArticle: action.data
            });

        default : return state;
    }
};