import _ from 'lodash';
import moment from 'moment';

export default (
    state = {
        totalEvent: 0,
        selectedTalkshow: {
            name: '',
            address: '',
            city: '',
            startTime: '00:00',
            endTime: '00:00',
            isPublish: true,
            eventDate: moment().format('DD/MM/YYYY'),
            modifiedDate: ''
        }
    },
    action
) => {
    let talkshows = {};
    let talkshowUsers = {};

    function pad(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    switch (action.type)
    {
        case 'TALKSHOW_SHOW_LOADER_ALL_TALKSHOWS':
            return Object.assign({}, state, {
                allTalkshowsLoader: true
            });
        case 'TALKSHOW_GET_ALL_TALKSHOWS':
            talkshows = _.map(action.talkshows, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                const eventDate = row.eventDate.split('/');
                row.eventDate = `${eventDate[2]}-${eventDate[1]}-${eventDate[0]}`;
                row.key = key;
                row.eventTime = row.startTime + ' - ' + row.endTime;
                
                return row;
            });

            return Object.assign({}, state, {
                allTalkshows: talkshows,
                allTalkshowsLoader: false,
            });
        case 'TALKSHOW_SHOW_LOADER_GET_TALKSHOW':
                return Object.assign({}, state, {
                    getTalkshowLoader: true,
                    selectedTalkshow: {
                        name: '',
                        address: '',
                        city: '',
                        startTime: '00:00',
                        endTime: '00:00',
                        isPublish: true,
                        eventDate: moment().format('DD/MM/YYYY')
                    }
                });
        case 'TALKSHOW_SELECT_TALKSHOW':
            return Object.assign({}, state, {
                selectedTalkshow: action.talkshow,
                getTalkshowLoader: false
            });
        case 'TALKSHOW_SHOW_LOADER_INSERT_TALKSHOW':
        case 'TALKSHOW_SHOW_LOADER_UPDATE_TALKSHOW':
            return Object.assign({}, state, {
                isLoader: true
            });
        case 'TALKSHOW_HIDE_LOADER_INSERT_TALKSHOW':
            return Object.assign({}, state, {
                isLoader: false
            });
        case 'TALKSHOW_HIDE_LOADER_UPDATE_TALKSHOW':
            return Object.assign({}, state, {
                isLoader: false,
                selectedTalkshow: action.data
            });

        case 'TALKSHOW_SHOW_LOADER_ALL_USERS':
            return Object.assign({}, state, {
                allTalkshowUsersLoader: true
            });

        case 'TALKSHOW_GET_ALL_USERS':
            talkshowUsers = _.map(action.talkshowUsers, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                row.birthday = moment(`${parseInt(row.birthday.year)}-${pad(parseInt(row.birthday.month) + 1, 2)}-${pad(parseInt(row.birthday.date), 2)}`).format('DD MMMM YYYY');
                return row;
            });

            return Object.assign({}, state, {
                allTalkshowUsers: talkshowUsers,
                allTalkshowUsersLoader: false,
            });

        default: return state;
    }
};