import _ from 'lodash';

export default (
    state = {
        allGalleryCategories: [],
        allGalleryAlbums: [],
        allGalleryMedias: [],

        selectedGalleryCategory: {
            name: '',
            orderNumber: 0,
            isPublish: true,
            modifiedDate: ''
        },
        selectedGalleryAlbum: {
            title: '',
            idCategory: '',
            isPublish: true,
            modifiedDate: '',
            albumDate: ''
        },
        selectedGalleryMedia: {
            caption: '',
        }
    },
    action
) => {
    let categories = [];
    let albums = [];
    let medias = [];

    switch (action.type)
    {
        case 'GALLERY_SHOW_LOADER_ALL_CATEGORIES':
            return Object.assign({}, state, {
                allGalleryCategoriesLoader: true
            });

        case 'GALLERY_GET_ALL_CATEGORIES':
            categories = _.map(action.categories, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                row.key = key;
                
                return row;
            });

            categories = categories.sort(function(a, b){
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            });

            return Object.assign({}, state, {
                allGalleryCategories: categories,
                allGalleryCategoriesLoader: false,
            });

        case 'GALLERY_SHOW_LOADER_GET_CATEGORY':
            return Object.assign({}, state, {
                getGalleryCategoryLoader: true,
                selectedGalleryCategory: {
                    name: '',
                    orderNumber : 0,
                    isPublish: true,
                    modifiedDate: ''
                }
            }); 

        case 'GALLERY_SELECT_CATEGORY':
            return Object.assign({}, state, {
                selectedGalleryCategory: action.category,
                getGalleryCategoryLoader: false
            });

        case 'GALLERY_SHOW_LOADER_INSERT_CATEGORY':
        case 'GALLERY_SHOW_LOADER_UPDATE_CATEGORY':
            return Object.assign({}, state, {
                isLoader: true
            });

        case 'GALLERY_HIDE_LOADER_INSERT_CATEGORY':
            return Object.assign({}, state, {
                isLoader: false
            });
            
        case 'GALLERY_HIDE_LOADER_UPDATE_CATEGORY':
            return Object.assign({}, state, {
                isLoader: false,
                selectedGalleryCategory: action.data
            });

        case 'GALLERY_SHOW_LOADER_ALL_ALBUMS':
            return Object.assign({}, state, {
                allGalleryAlbumsLoader: true
            });

        case 'GALLERY_GET_ALL_ALBUMS':
            albums = _.map(action.albums, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                const albumDate = row.albumDate.split('/');
                row.albumDate = `${albumDate[2]}-${albumDate[1]}-${albumDate[0]}`;
                row.mediaCount = row.medias ? Object.keys(row.medias).length : 0;
                
                row.key = key;
                row.status = row.isPublish === true ? 'Publish' : 'Draft';

                return row;
            });

            return Object.assign({}, state, {
                allGalleryAlbums: albums ,
                allGalleryAlbumsLoader: false,
            });

        case 'GALLERY_SHOW_LOADER_GET_ALBUM':
            return Object.assign({}, state, {
                getGalleryAlbumLoader: true,
                selectedGalleryAlbum: {
                    title: '',
                    albumDate: '',
                    coverFileName: '',
                    cover: '',
                    isPublish: true,
                    modifiedDate: '',
                    medias: []
                }
            }); 
        
        case 'GALLERY_SELECT_ALBUM':
            if(action.album){
                if(action.album.medias !== undefined){
                    medias = _.map(action.album.medias, (row, key) => {
                        row.key = key;
                        row.mediaType = row.isVideo ? ( row.isExternalSource ? 'video_external' : 'video' ) : 'image';

                        return row;
                    }); 
                }

                const albumDate = action.album.albumDate.split('/');
                action.album.albumDate = `${albumDate[2]}-${albumDate[1]}-${albumDate[0]}`;
            }

            return Object.assign({}, state, {
                selectedGalleryAlbum: action.album,
                getGalleryAlbumLoader: false,
                
                allGalleryMedias: medias
            });
        
        case 'GALLERY_SHOW_LOADER_INSERT_ALBUM':
        case 'GALLERY_SHOW_LOADER_UPDATE_ALBUM':
            return Object.assign({}, state, {
                isLoader: true
            });

        case 'GALLERY_HIDE_LOADER_INSERT_ALBUM':
            return Object.assign({}, state, {
                isLoader: false
            });
            
        case 'GALLERY_HIDE_LOADER_UPDATE_ALBUM':
            return Object.assign({}, state, {
                isLoader: false,
                selectedGalleryAlbum: action.data
            });

        case 'GALLERY_SHOW_LOADER_ALL_MEDIAS':
            return Object.assign({}, state, {
                allGalleryMediasLoader: true
            });
        
        case 'GALLERY_GET_ALL_MEDIAS':
            medias = _.map(action.medias, (row, key) => {
                row.key = key;
                row.mediaType = row.isVideo ? ( row.isExternalSource ? 'video_external' : 'video' ) : 'image';

                return row;
            });

            return Object.assign({}, state, {
                allGalleryMedias: medias ,
                allGalleryMediasLoader: false,
            });

        case 'GALLERY_SHOW_LOADER_INSERT_MEDIA':
        case 'GALLERY_SHOW_LOADER_UPDATE_MEDIA':
            return Object.assign({}, state, {
                isLoader: true
            });

        case 'GALLERY_HIDE_LOADER_INSERT_MEDIA':
            return Object.assign({}, state, {
                isLoader: false
            });
            
        case 'GALLERY_HIDE_LOADER_UPDATE_MEDIA':
            return Object.assign({}, state, {
                isLoader: false,
                selectedGalleryMedia: action.data
            });

        case 'GALLERY_SHOW_LOADER_GET_MEDIA':
            return Object.assign({}, state, {
                getGalleryMediaLoader: true,
                selectedGalleryMedia: {
                    caption: '',
                }
            }); 
        
        case 'GALLERY_SELECT_MEDIA':
            return Object.assign({}, state, {
                selectedGalleryMedia: action.media,
                getGalleryMediaLoader: false,
            });

        default : return state;
    }
};