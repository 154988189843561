import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import PrivateRoute from 'components/privateRoute';
import PublicRoute from 'components/publicRoute';

// Import your pages component here...
const Auth = {
    LoginPage: Loadable({
        loader: () => import('pages/auth/login'),
        loading: () => null
    })
};
const Private = {
    DashboardPage: Loadable({
        loader: () => import('pages/dashboard'),
        loading: () => null
    }),
    Module: {
        // user
        UserPage: Loadable({
            loader: () => import('pages/module/user'),
            loading: () => null
        }),
        UserDetail: Loadable({
            loader: () => import('pages/module/user-detail'),
            loading: () => null
        }),

        // agen
        AgentPage: Loadable({
            loader: () => import('pages/module/agent'),
            loading: () => null
        }),
        AgentDetail: Loadable({
            loader: () => import('pages/module/agent-detail'),
            loading: () => null
        }),

        // event roadshow
        RoadshowPage: Loadable({
            loader: () => import('pages/module/roadshow'),
            loading: () => null
        }),

        RoadshowNew: Loadable({
            loader: () => import('pages/module/roadshow-new'),
            loading: () => null 
        }),
        
        RoadshowEdit: Loadable({
            loader: () => import('pages/module/roadshow-edit'),
            loading: () => null 
        }),
        
        RoadshowUser: Loadable({
            loader: () => import('pages/module/roadshow-user'),
            loading: () => null 
        }),
        
        // islamic talkshow
        TalkshowPage: Loadable({
            loader: () => import('pages/module/talkshow'),
            loading: () => null
        }),
        
        TalkshowNew: Loadable({
            loader: () => import('pages/module/talkshow-new'),
            loading: () => null 
        }),

        TalkshowEdit: Loadable({
            loader: () => import('pages/module/talkshow-edit'),
            loading: () => null 
        }),

        TalkshowUser: Loadable({
            loader: () => import('pages/module/talkshow-user'),
            loading: () => null 
        }),

        // galery photo
        // galery photo - category
        GalleryCategoryPage: Loadable({
            loader: () => import('pages/module/gallery-category'),
            loading: () => null
        }),

        GalleryCategoryNew: Loadable({
            loader: () => import('pages/module/gallery-category-new'),
            loading: () => null 
        }),

        GalleryCategoryEdit: Loadable({
            loader: () => import('pages/module/gallery-category-edit'),
            loading: () => null 
        }),

        // gallery photo - album
        GalleryAlbumPage: Loadable({
            loader: () => import('pages/module/gallery-album'),
            loading: () => null
        }),

        GalleryAlbumNew: Loadable({
            loader: () => import('pages/module/gallery-album-new'),
            loading: () => null
        }),
        
        GalleryAlbumEdit: Loadable({
            loader: () => import('pages/module/gallery-album-edit'),
            loading: () => null
        }),
        
        // gallery photo - media
        GalleryMediaPage: Loadable({
            loader: () => import('pages/module/gallery-media'),
            loading: () => null
        }),
        
        GalleryMediaNew: Loadable({
            loader: () => import('pages/module/gallery-media-new'),
            loading: () => null
        }),
        
        GalleryMediaEdit: Loadable({
            loader: () => import('pages/module/gallery-media-edit'),
            loading: () => null
        }),

        // end gallery
        
        // article
        // article - category
        ArticleCategoryPage: Loadable({
            loader: () => import('pages/module/article-category'),
            loading: () => null
        }),
        
        ArticleCategoryNew: Loadable({
            loader: () => import('pages/module/article-category-new'),
            loading: () => null 
        }),
        
        ArticleCategoryEdit: Loadable({
            loader: () => import('pages/module/article-category-edit'),
            loading: () => null 
        }),
        
        // article - single
        ArticlePage: Loadable({
            loader: () => import('pages/module/article'),
            loading: () => null
        }),
        
        ArticleNew: Loadable({
            loader: () => import('pages/module/article-new'),
            loading: () => null
        }),
        
        ArticleEdit: Loadable({
            loader: () => import('pages/module/article-edit'),
            loading: () => null
        }),
        // end gallery

        // showcase
        ShowcasePage: Loadable({
            loader: () => import('pages/module/showcase'),
            loading: () => null
        }),
    },
};
const ErrorPage = Loadable({
    loader: () => import('pages/error'),
    loading: () => null
});

export default () => (
    <Router>
        <Switch>
            {/* Auth Routes */}
            <PublicRoute path="/auth/login" component={Auth.LoginPage} />
            
            {/* Private Routes */}
            <PrivateRoute exact path="/" component={Private.DashboardPage} />
            
            {/* user */}
            <PrivateRoute exact path="/module/participant" component={Private.Module.UserPage} />
            <PrivateRoute path="/module/participant/:id" component={Private.Module.UserDetail} />
            
            {/* agent */}
            <PrivateRoute exact path="/module/agent" component={Private.Module.AgentPage} />
            <PrivateRoute path="/module/agent/:id" component={Private.Module.AgentDetail} />
            
            {/* event roadshow */}
            <PrivateRoute exact path="/module/roadshow" component={Private.Module.RoadshowPage} />
            <PrivateRoute exact path="/module/roadshow/new" component={Private.Module.RoadshowNew} />
            <PrivateRoute path="/module/roadshow/edit/:id" component={Private.Module.RoadshowEdit} />
            <PrivateRoute path="/module/roadshow/participant/:id" component={Private.Module.RoadshowUser} />

            {/* islamic talkshow */}
            <PrivateRoute exact path="/module/talkshow" component={Private.Module.TalkshowPage} />
            <PrivateRoute exact path="/module/talkshow/new" component={Private.Module.TalkshowNew} />
            <PrivateRoute path="/module/talkshow/edit/:id" component={Private.Module.TalkshowEdit} />
            <PrivateRoute path="/module/talkshow/participant/:id" component={Private.Module.TalkshowUser} />

            {/* gallery */}
                {/* category */}
                <PrivateRoute exact path="/module/category-gallery" component={Private.Module.GalleryCategoryPage} />
                <PrivateRoute exact path="/module/category-gallery/new" component={Private.Module.GalleryCategoryNew} />
                <PrivateRoute path="/module/category-gallery/edit/:id" component={Private.Module.GalleryCategoryEdit} />

                {/* album */}
                <PrivateRoute exact path="/module/gallery" component={Private.Module.GalleryAlbumPage} />                
                <PrivateRoute exact path="/module/gallery/new" component={Private.Module.GalleryAlbumNew} />
                <PrivateRoute path="/module/gallery/edit/:id" component={Private.Module.GalleryAlbumEdit} />

                {/* media */}
                <PrivateRoute exact path="/module/gallery/media/:id" component={Private.Module.GalleryMediaPage} />
                <PrivateRoute exact path="/module/gallery/media/new/:id" component={Private.Module.GalleryMediaNew} />
                <PrivateRoute exact path="/module/gallery/media/edit/:id_album/:id" component={Private.Module.GalleryMediaEdit} />

            {/* end gallery */}

            {/* article */}
                {/* category */}
                <PrivateRoute exact path="/module/category-article" component={Private.Module.ArticleCategoryPage} />
                <PrivateRoute exact path="/module/category-article/new" component={Private.Module.ArticleCategoryNew} />
                <PrivateRoute path="/module/category-article/edit/:id" component={Private.Module.ArticleCategoryEdit} />

                {/* single */}
                <PrivateRoute exact path="/module/article" component={Private.Module.ArticlePage} />                
                <PrivateRoute exact path="/module/article/new" component={Private.Module.ArticleNew} />
                <PrivateRoute path="/module/article/edit/:id" component={Private.Module.ArticleEdit} />
            {/* end article */}

            {/* showcase */}
                <PrivateRoute exact path="/module/showcase" component={Private.Module.ShowcasePage} />

            {/* Not Found */}
            <Route render={() => <ErrorPage code="404"/>} />
        </Switch>
    </Router>
);