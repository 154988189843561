import _ from 'lodash';
export default (
    state = {
        totalAgent: 0,
        lastAgents: {},
        nextKey: '',
        selectedAgent: {
            name: '-',
            email: '-',
            phone: '-',
            referralCode: '-'
        },
        allAgentsLoader: false,
        getAgentLoader: false
    },
    action
) => {
    let agents = {};

    switch (action.type)
    {
        case 'AGENT_SHOW_LOADER_ALL_AGENTS':
            return Object.assign({}, state, {
                allAgentsLoader: true
            });
        case 'AGENT_SHOW_LOADER_GET_AGENT':
            return Object.assign({}, state, {
                getAgentLoader: true,
                selectedAgent: {
                    name: '-',            
                    email: '-',
                    phone: '-',
                    referralCode: '-'
                }
            });
        case 'AGENT_SELECT_AGENT':
            return Object.assign({}, state, {
                selectedAgent: action.agent,
                getAgentLoader: false
            });
        case 'AGENT_GET_ALL_AGENTS':
            agents = _.map(action.agents, (row, key) => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                row.key = key;

                row.candidates = _.map(row.candidates, (c_row, c_key) => {
                    c_row.contest = c_row.contest === 'hitzjab-hunt' ? 'Hijab Muda' : (c_row.contest === 'nada-islami' ? 'Nada Islami' : ( c_row.contest === 'santripreneur' ? 'Santripreneur' : 'N/A'));
                    return c_row;
                });

                return row;
            });

            agents.sort(
                (a,b) => (a.createdDate > b.createdDate) ? -1 : ((b.createdDate > a.createdDate) ? 1 : 
                    (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
                )
            );

            return Object.assign({}, state, {
                allAgents: agents,
                allAgentsLoader: false,
            });
        default: return state;
    }
};