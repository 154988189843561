import _ from 'lodash';
import moment from 'moment';

export default (
    state = {
        totalUser: 0,
        totalAgent: 0,
        lastUsers: {},
        nextKey: '',
        selectedUser: {
            fullname: '-',
            birthday: {
                date: '',
                month: '',
                year: ''
            },
            photo: 'https://via.placeholder.com/100'
        },
        lastUsersLoader: false,
        getUserLoader: false
    },
    action
) => {
    let users = {};

    switch (action.type)
    {
        case 'USER_SHOW_LOADER_LAST_USERS':
            return Object.assign({}, state, {
                lastUsersLoader: true
            });
        case 'USER_SHOW_LOADER_GET_USER':
            return Object.assign({}, state, {
                getUserLoader: true,
                selectedUser: {
                    fullname: '-',
                    birthday: {
                        date: '',
                        month: '',
                        year: ''
                    },
                    photo: 'https://via.placeholder.com/100'
                }
            });
        case 'USER_SELECT_USER':
            let createdDate = action.user.createdDate;
            let modifiedDate = action.user.modifiedDate;
            createdDate = createdDate.split('/');
            modifiedDate = modifiedDate.split('/');
            action.user.createdDate = moment(`${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`).format('DD MMMM YYYY');
            action.user.modifiedDate = moment(`${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`).format('DD MMMM YYYY');

            return Object.assign({}, state, {
                selectedUser: action.user,
                getUserLoader: false
            });
        case 'USER_GET_LAST_USERS':
            // Change createdDate and modifiedDate
            users = _.map(action.users, row => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                return row;
            });

            // Set next key if the users count > 10
            let nextKey = '';
            if (users.length > 10) nextKey = users[users.length - 1].key;
            return Object.assign({}, state, {
                lastUsers: users,
                lastUsersLoader: false,
                nextKey
            });
        case 'USER_GET_TOTAL_AGENT':
            return Object.assign({}, state, {
                totalAgent: action.totalAgent
            });
        case 'USER_GET_TOTAL_USER':
            return Object.assign({}, state, {
                totalUser: action.totalUser
            });
        case 'USER_SHOW_LOADER_ALL_USERS':
            return Object.assign({}, state, {
                allUsersLoader: true
            });
        case 'USER_GET_ALL_USERS':
            // Change createdDate and modifiedDate
            users = _.map(action.users, row => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;
                return row;
            });

            return Object.assign({}, state, {
                allUsers: users,
                allUsersLoader: false
            });

        case 'USER_SHOW_LOADER_ALL_SHORTLISTED_USERS':
            return Object.assign({}, state, {
                allShortlistedUsersLoader: true
            });
        
        case 'USER_GET_ALL_SHORTLISTED_USERS':
            // Change createdDate and modifiedDate
            users = _.map(action.users, row => {
                const createdDate = row.createdDate.split('/');
                row.createdDate = `${createdDate[2]}-${createdDate[1]}-${createdDate[0]}`;
                const modifiedDate = row.modifiedDate.split('/');
                row.modifiedDate = `${modifiedDate[2]}-${modifiedDate[1]}-${modifiedDate[0]}`;

                row.event = row.type === 'hitzjab-hunt' ? 'Hijab Muda' : (row.type === 'nada-islami' ? 'Nada Islami' : ( row.type === 'santripreneur' ? 'Santripreneur' : 'N/A'));
                row.domicile = row.province === undefined || row.city === undefined ? '' : `${row.city}, ${row.province}`;
                row.totalVote = row.totalVote ? row.totalVote : 0;
                row.isActive = row.isActive === undefined ? true : row.isActive;
                return row;
            });

            return Object.assign({}, state, {
                allShortlistedUsers: users,
                allShortlistedUsersLoader: false
            });
        
        default: return state;
    }
};