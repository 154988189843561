import React from 'react';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import Routes from './routes';
import rootReducers from './reducers';

/**
 * Notes on redux use, just replace the first parameter of "createStore" to rootReducers above.
 * Don't forget to uncomment.
 */

// Redux Init
const store = createStore(
    rootReducers,
    {},
    applyMiddleware(
        reduxThunk
    )
);

export default () => (
    <Provider store={store}>
        <Routes />
    </Provider>
);